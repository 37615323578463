import React, { useState } from 'react'
import { Trans, useLingui } from '@lingui/react'
import styled from 'styled-components'
import OnboardingModal from 'components/pages/onboarding/OnboardingModal'
import Input from 'components/util/Input'
import PrimaryButton from 'components/util/PrimaryButton'
import Table from 'components/util/Table'
import { validateEmail } from 'util/index'
import Icon from 'components/util/Icon'
import { APP_NAME } from '../../../../util/constants'

const AdminOnboardingModalInviteUsers = ({ formState, ...props }) => {
    const [emailError, setEmailError] = useState(false)
    const { i18n } = useLingui()
    const {
        getNativeInputProps,
        handleAddEmailAddress,
        handleRemoveEmailAddress,
        values,
    } = formState
    return (
        <OnboardingModal
            title={
                <Trans
                    id="Welcome to the {APP_NAME} App"
                    values={{ APP_NAME }}
                />
            }
            subTitle={
                <>
                    <Trans id="Let's invite some users" />
                    <br />
                    <Trans id="Don't worry, you can always invite users later" />
                </>
            }
            {...props}
        >
            <EmailInput>
                <StyledInput
                    hasAddonAfter
                    placeholder={i18n._('Email')}
                    hasError={emailError}
                    {...getNativeInputProps('emailInput')}
                />
                <PrimaryButton
                    hasAddonBefore
                    isAddonAfter
                    onClick={() => {
                        if (!validateEmail(values.emailInput)) {
                            setEmailError(true)
                        } else {
                            setEmailError(false)
                            handleAddEmailAddress(values.emailInput)
                        }
                    }}
                >
                    <Trans id="Add user" />
                </PrimaryButton>
            </EmailInput>
            {values.emailAddresses.length > 0 && (
                <Table
                    data={values.emailAddresses}
                    columnWidths={['auto', 2]}
                    header={<UserTableHeaderRow />}
                    renderRow={(emailAddress, index) => (
                        <UserTableRow
                            key={index}
                            index={index}
                            emailAddress={emailAddress}
                            handleRemoveEmailAddress={handleRemoveEmailAddress}
                        />
                    )}
                />
            )}
        </OnboardingModal>
    )
}

const UserTableHeaderRow = () => (
    <Table.Row>
        <Table.HeaderCell>
            <Trans id="Email" />
        </Table.HeaderCell>
        <Table.HeaderCell />
    </Table.Row>
)

const UserTableRow = ({ emailAddress, index, handleRemoveEmailAddress }) => {
    const { email } = emailAddress
    return (
        <Table.Row>
            <Table.Cell>{email}</Table.Cell>
            <Table.Cell textAlign="right">
                <Icon
                    isSmaller
                    name="cross"
                    onClick={() => handleRemoveEmailAddress(index)}
                />
            </Table.Cell>
        </Table.Row>
    )
}

const EmailInput = styled.div(
    ({ theme }) => `
    display: flex;
    padding-top: ${theme.spacingSmaller}rem;
    padding-bottom: ${theme.spacingSmaller}rem;
`,
)

const StyledInput = styled(Input)`
    flex: 1;
    min-width: 30rem;
`

export default AdminOnboardingModalInviteUsers
